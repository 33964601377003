<template>
  <el-dialog title="选择问卷" :visible.sync="visible" width="700px" :close-on-click-modal="false">
    <div class="question-con">
      <div class="con-item">
        <div class="item-title">问卷分类</div>
        <div class="question-classify">
          <el-tree
            class="custom-tree"
            node-key="value"
            :data="data"
            :props="defaultProps"
            :current-node-key="1"
            accordion
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
      </div>
      <div class="con-item">
        <div class="item-title">问卷名称</div>
        <div class="question-name">
          <el-radio-group v-model="questionNameId" v-if="questionList.length>0">
            <el-radio
            class="my-radio"
              v-for="(element,index) in questionList"
              :key="index"
              :label="element.id"
              style="width:100%;margin-bottom:10px;"
            >{{element.libraryName}}</el-radio>
          </el-radio-group>
          <div v-else>暂无数据</div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="confirmQustionId">确定</el-button>
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      data: [],
      current: "",
      defaultProps: {
        children: "children",
        label: "name",
        value: "value"
      },
      questionNameId: "",
      questionList: []
    };
  },
  methods: {
    init() {
      let _this = this;
      _this.visible = true;
      _this.getDictList();
      _this.getQuestionList(1)
    },
    // 获取问卷分类
    getDictList() {
      let _this = this;
      _this
        .$http({
					url:this.$http.adornUrl("/dict/getSysDicts?type=" + "表单类别"),
          method:'get',
          params:{
            teamsId:JSON.parse(localStorage.currentTeam).id,
            // hospitalId:this.$cookie.get('hospitalId'),
            pageSize:999,
            pageNo:1,
          }
          }).then(({ data }) => {
          if (data.status) {
            _this.data = data.data;
          }
        });
    },
    // 获取问卷名称
    getQuestionList(type) {
      let _this = this;
      let unionId = "";
      if (localStorage.userInfo) {
        unionId = JSON.parse(localStorage.userInfo).doctorUnionId;
      }
      let params = {
        pageSize: 10000,
        pageNo: 1,
        unionId: unionId,
        contentType: type
      };
      _this
        .$http({
          url: _this.$http.adornUrl("/indexLibrary/list"),
          method: "get",
          params: params
        })
        .then(({ data }) => {
          if (data.status) {
            _this.questionList = data.data;
          }
        });
    },
    // 提交信息
    confirmQustionId () {
      let _this= this;
      let params = {
        questionNameId: _this.questionNameId,
        questionName:"",
      }
      _this.questionList.forEach((ele)=>{
        if (ele.id == _this.questionNameId) {
          params.questionName = ele.libraryName;
        }
      })
      _this.$emit('setQuestionId', params)
      _this.visible = false;
    },
    handleNodeClick(data) {

      console.log(data);
      this.getQuestionList(data.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.question-con {
  display: flex;
  .con-item {
    flex: 1;
    .item-title {
      text-align: center;
      padding-bottom: 20px;
    }
    .question-classify {
      padding: 20px;
      height: 400px;
      overflow-y: auto;
      border: 1px solid #eee;
      background: #fff;
    }
    .question-name {
      padding: 20px;
    }
  }
}
</style>


